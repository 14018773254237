/** @jsx jsx */

import React from "react"
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import { GTWHBold, GTWHRegular, Colors } from "../components/styles"
import { desktop } from "../components/responsive"

import logo_tw from "../assets/images/logos/tanzwerk-101.svg"
import logo_sbb from "../assets/images/logos/sbb.svg"
import logo_sv from "../assets/images/logos/shopville.svg"
import logo_stzh from "../assets/images/logos/stadt-zuerich.svg"
import logo_r1 from "../assets/images/logos/radio-1.svg"

const Title = styled.h4({
  ...GTWHRegular,
  fontSize: 20,
  margin: `16px 0 16px 0`,
})
const Container = styled.div({
  display: `flex`,
  flexWrap: `wrap`,
})
const Logo = styled.img({
  marginRight: 48,
  marginBottom: 16,
})

export const SectionLogoJungle = ({ images }) => (
  <div
    css={{
      ...desktop({
        marginTop: 60,
      }),
    }}
  >
    <Title>Partner</Title>
    <Container>
      <Logo src={logo_tw} />
      <Logo src={logo_sbb} />
      <Logo src={logo_sv} />
    </Container>

    <Title>Patronat</Title>
    <Container>
      <Logo src={logo_stzh} />
    </Container>

    <Title>Medienpartner</Title>
    <Container>
      <Logo src={logo_r1} />
    </Container>
  </div>
)
