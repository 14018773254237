import { desktop } from "./responsive"

export const Colors = {
  white: `#fefefe`,
  yellow: `#fff29c`,
}

export const vwDesktop = px => `${px / 13.6}vw`
export const vwMobile = px => `${px / 3.75}vw`

//
// typo
//
export const GTWHRegular = {
  fontFamily: `'GTWHRegular'`,
  fontWeight: `normal`,
  fontStyle: `normal`,
}
export const GTWHBold = {
  fontFamily: `'GTWHBold'`,
  fontWeight: `normal`,
  fontStyle: `normal`,
}

// fonts
export const FontLandingPageRegular = {
  fontSize: `6.7vw`,
  lineHeight: 1.12,
  ...desktop({
    fontSize: vwDesktop(44),
    lineHeight: 1,
  }),
}
export const FontLandingPageBold = {
  fontSize: `21.7vw`,
  lineHeight: 0.8,
  ...desktop({ fontSize: `12.87vw` }),
}

// menu
export const TypoMenuMobile = {
  ...GTWHRegular,
  ...FontLandingPageRegular,
  color: Colors.white,
  lineHeight: 1.12,
  textDecoration: `none`,
}

// landing page
export const TypoLandingPageRegular = {
  ...GTWHRegular,
  ...FontLandingPageRegular,
  color: Colors.yellow,
}
export const TypoLandingPageBold = {
  ...GTWHBold,
  ...FontLandingPageBold,
  color: Colors.yellow,
}
