/** @jsx jsx */

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticPageLayout } from "../components/layout"
import { jsx } from "@emotion/core"
import {
  vwMobile,
  vwDesktop,
  GTWHBold,
  GTWHRegular,
  Colors,
} from "../components/styles"
import { desktop } from "../components/responsive"
import remark from "remark"
import remark2react from "remark-react"
import { SectionGallery } from "./gallery"
import { SectionLogoJungle } from "./logojungle"

const Page = ({ data }) => (
  <StaticPageLayout>
    <div
      css={{
        padding: `0 ${vwMobile(10)}`,
        ...desktop({
          marginTop: vwDesktop(90),
          padding: `0 ${vwDesktop(8)}`,
        }),
      }}
    >
      <h1
        css={{
          ...GTWHBold,
          fontSize: 44,
          lineHeight: 1.25,
          marginBottom: 24,
        }}
      >
        {data.pagesJson.title}
      </h1>
      {data.pagesJson.sections.map((section, i) => (
        <Section key={i} {...section} />
      ))}
    </div>
  </StaticPageLayout>
)

const MD = ({ text }) => (
  <div
    css={{
      ...GTWHRegular,
      fontSize: 20,
      lineHeight: 1.3,
      p: {
        marginTop: 15,
        marginBottom: 24,
        "&:first-child": {
          marginTop: 0,
        },
      },
      a: {
        color: Colors.white,
        borderBottom: `1px solid ${Colors.white}`,
        textDecoration: `none`,
      },
      strong: {
        ...GTWHBold,
      },
    }}
  >
    {
      remark()
        .use(remark2react)
        .processSync(text).contents
    }
  </div>
)

const Section = section =>
  section.type === `subtitle` ? (
    <SectionSubtitle {...section} />
  ) : section.type === `text` ? (
    <SectionText {...section} />
  ) : section.type === `timetable` ? (
    <SectionTimetable {...section} />
  ) : section.type === `gallery` ? (
    <SectionGallery {...section} />
  ) : section.type === `video` ? (
    <SectionVideo {...section} />
  ) : section.type === `logojungle` ? (
    <SectionLogoJungle {...section} />
  ) : null

const SectionSubtitle = ({ subtitle }) => (
  <h2
    css={{
      marginTop: 60,
      marginBottom: 15,
      fontSize: 30,
      ...GTWHRegular,
      fontWeight: `normal`,
    }}
  >
    {subtitle}
  </h2>
)
const SectionText = ({ text }) => <MD text={text} />

const TimetableEntry = ({ start, description }) => (
  <div css={{ display: `flex` }}>
    <div
      css={{
        flex: `0 0 92px`,
      }}
    >
      <MD text={start} />
    </div>
    <MD text={description} />
  </div>
)
const SectionTimetable = ({ timetable }) =>
  timetable.map((entry, i) => <TimetableEntry key={i} {...entry} />)

const VideoVimeo = ({ href }) => (
  <>
    <iframe
      src={`https://player.vimeo.com/video/${href.substring(
        "https://vimeo.com/".length
      )}`}
      css={{
        position: `absolute`,
        top: 0,
        left: 0,
        width: `100%`,
        height: `100%`,
      }}
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
    />
    <script src="https://player.vimeo.com/api/player.js" />
  </>
)

const VideoYoutube = ({ href }) => (
  <iframe
    src="https://www.youtube-nocookie.com/embed/CQ6dbUmU__o"
    css={{
      position: `absolute`,
      top: 0,
      left: 0,
      width: `100%`,
      height: `100%`,
    }}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
)

// YT: https://youtu.be/CQ6dbUmU__o
// VO: https://vimeo.com/204416450
const SectionVideo = ({ href }) => (
  <div
    css={{
      padding: `56.25% 0 0 0`,
      position: `relative`,
      marginBottom: 10,
      ...desktop({ marginBottom: 24 }),
    }}
  >
    {href.indexOf(`https://vimeo.com/`) === 0 ? (
      <VideoVimeo {...{ href }} />
    ) : href.indexOf(`https://youtou.be/`) === 0 ? (
      <VideoYoutube {...{ href }} />
    ) : null}
  </div>
)

export const query = graphql`
  query($slug: String!) {
    pagesJson(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      title
      sections {
        type
        text
        subtitle
        timetable {
          start
          description
        }
        images {
          image
        }
        href
      }
    }
  }
`
export default Page
