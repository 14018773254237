/** @jsx jsx */

import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { TypoMenuMobile, vwDesktop, GTWHRegular, Colors } from "./styles"
import { jsx } from "@emotion/core"
import { desktop } from "./responsive"
import { Location } from "@reach/router"

import menu from "../assets/images/menu.svg"
import close from "../assets/images/close.svg"

const MenuItem = ({ itemCss, title, path }) => (
  <Location>
    {({ location }) => (
      <div css={{ ...itemCss }}>
        <Link
          to={path}
          css={{
            textDecoration: `none`,
            color: Colors.white,
            borderBottom:
              path === location.pathname ? `2px solid ${Colors.white}` : `none`,
          }}
        >
          {title}
        </Link>
      </div>
    )}
  </Location>
)

const MenuItems = ({ itemCss }) => {
  const pages = useStaticQuery(graphql`
    query Menu {
      allPagesJson(sort: { fields: [displayOrder], order: ASC }) {
        edges {
          node {
            title
            displayOrder
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return (
    <>
      {pages.allPagesJson.edges.map(
        ({ node }) =>
          node.displayOrder > 0 && (
            <MenuItem
              itemCss={itemCss}
              key={node.fields.slug}
              title={node.title}
              path={node.fields.slug}
            />
          )
      )}
    </>
  )
}

export const MenuMobile = () => {
  const [visible, setVisible] = useState(false)
  const itemCss = {
    ...TypoMenuMobile,
    marginTop: 20,
    display: `block`,
    textAlign: `center`,
  }

  const transition = {
    transitionDuration: `.175s`,
    transitionTimingFunction: `ease`,
  }

  return (
    <div css={{ ...desktop({ display: `none` }) }}>
      <div
        css={{
          ...transition,
          transitionProperty: `background-color`,
          display: `flex`,
          flexDirection: `column`,
          background: visible ? `#c708aa` : `inherit`,
          flex: 1,
          alignItems: `center`,
          marginBottom: 20,
          minHeight: 80,
        }}
      >
        <img
          css={{
            width: 40,
            marginTop: 24,
            marginBottom: visible ? 0 : 14,
          }}
          src={visible ? close : menu}
          alt="menu"
          onClick={() => setVisible(!visible)}
        />
        <nav
          css={{
            ...transition,
            transitionProperty: `opacity, padding, max-height`,
            opacity: visible ? 1 : 0,
            zIndex: visible ? 10 : 0,
            maxHeight: visible ? 400 : 0,
            overflow: `hidden`,
            padding: visible ? `0 12px 20px 12px` : 0,
            ...TypoMenuMobile,
          }}
        >
          <Location>
            {({ location }) =>
              location.pathname === "/" ? null : (
                <Link to="/" css={{ ...itemCss }}>
                  Home
                </Link>
              )
            }
          </Location>
          <MenuItems {...{ itemCss }} />
        </nav>
      </div>
    </div>
  )
}

export const MenuDesktop = () => {
  const itemCss = {
    ...GTWHRegular,
    fontSize: vwDesktop(44),
    color: Colors.white,
    textDecoration: `none`,
    lineHeight: 1.137,
    marginBottom: `2.65vw`,
    paddingLeft: `2.57vw`,
  }
  return (
    <nav
      css={{
        display: `none`,
        flex: 25,
        marginTop: vwDesktop(90),
        ...desktop({ display: `block` }),
      }}
    >
      <MenuItem itemCss={{ ...itemCss }} title="Home" path="/" />
      <MenuItems {...{ itemCss }} />
    </nav>
  )
}
