/** @jsx jsx */

import React from "react"
import { jsx } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import { desktop } from "../components/responsive"

const Image = ({ src }) => (
  <img
    css={{
      width: `100%`,
      height: `auto`,
      display: `block`,
      marginBottom: 10,
      alignSelf: `center`,
      ...desktop({
        width: `calc(50% - 12px)`,
        marginBottom: 24,
      }),
    }}
    src={src}
  />
)

export const SectionGallery = ({ images }) => {
  const allImages = useStaticQuery(graphql`
    query GalleryImages {
      allFile(filter: { extension: { regex: "/(jpeg|jpg|gif|png|svg)/" } }) {
        edges {
          node {
            publicURL
            base
            extension
            childImageSharp {
              resize(width: 600, height: 400, quality: 90) {
                src
                width
                height
                aspectRatio
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const getImageSrc = image => {
    const baseName = image.substring("/assets/".length)
    const match = allImages.allFile.edges.filter(
      edge =>
        (edge.node.childImageSharp &&
          edge.node.childImageSharp.resize.originalName === baseName) ||
        edge.node.base === baseName
    )[0]

    return match.node.childImageSharp
      ? match.node.childImageSharp.resize.src
      : match.node.publicURL
  }

  return (
    images &&
    images.length > 0 && (
      <div
        css={{
          ...desktop({
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            marginTop: 24,
          }),
        }}
      >
        {images.map((image, i) => (
          <Image key={i} src={getImageSrc(image.image)} />
        ))}
      </div>
    )
  )
}
