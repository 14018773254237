/** @jsx jsx */

import React, { useEffect } from "react"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import { MenuMobile, MenuDesktop } from "./menu"
import { Global, css } from "@emotion/core"
import { jsx } from "@emotion/core"
import SEO from "./seo"
import { desktop } from "./responsive"
import { vwDesktop, vwMobile } from "./styles"

import "./normalize.css"
import "../assets/fonts/fonts.css"

import logomkp from "../assets/images/logomkp.svg"
import logomkpStatic from "../assets/images/logomkp-static.svg"

export const colorAnimationTime = `60s`

const LogoMKPLandingPage = () => (
  <div
    css={{
      display: `flex`,
      justifyContent: `flex-end`,
      marginTop: vwMobile(24),
      marginBottom: 80,

      ...desktop({
        flex: 20,
        alignItems: `flex-end`,
        marginTop: 0,
      }),
    }}
  >
    <img
      css={{
        width: 98,
        height: 51,
        marginRight: vwMobile(24),
        ...desktop({
          marginRight: vwDesktop(25),
        }),
      }}
      src={logomkp}
    />
  </div>
)

const LogoMKPStaticPage = () => (
  <div
    css={{
      display: `flex`,
      justifyContent: `center`,
      marginTop: 40,
      ...desktop({
        flex: 20,
        alignItems: `flex-end`,
        marginTop: 0,
      }),
    }}
  >
    <img
      css={{
        width: 110,
        height: 126,
        ...desktop({
          position: `fixed`,
          bottom: 24,
          right: vwDesktop(25),
          height: `auto`,
          width: vwDesktop(220),
        }),
      }}
      src={logomkpStatic}
    />
  </div>
)

export const LandingPageLayout = props => {
  useEffect(() => {
    document.body.classList.add(`home`)
    return () => {
      document.body.classList.remove(`home`)
    }
  })
  return <Layout LogoMKP={<LogoMKPLandingPage />} {...props} />
}

export const StaticPageLayout = ({ children }) => (
  <Layout LogoMKP={<LogoMKPStaticPage />} {...{ children }} />
)

const Layout = ({ LogoMKP, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <SEO lang="de" />
      <Global
        styles={css`
          html,
          body {
            min-height: 100vh;
          }
          html {
            box-sizing: border-box;
          }
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }
          p,
          ul,
          h1,
          h2 {
            margin: 0;
            padding: 0;
          }

          @keyframes bgcolor {
            0% {
              background-color: #251957;
            }
            16.67% {
              background-color: #b9218a;
            }
            33.33% {
              background-color: #182472;
            }
            50% {
              background-color: #35145e;
            }
            66.67% {
              background-color: #195f73;
            }
            83.33% {
              background-color: #d01f37;
            }
            100% {
              background-color: #251957;
            }
          }

          body {
            background-color: #251957;
            color: white;
            padding-bottom: 80px;
          }

          body.home {
            animation: bgcolor ${colorAnimationTime} infinite;
          }
        `}
      />

      <div
        css={{
          display: `flex`,
          flexDirection: `column`,
          ...desktop({ flexDirection: `row` }),
        }}
      >
        <MenuMobile />
        <MenuDesktop />
        <main css={{ display: `flex`, flexDirection: `column`, flex: 55 }}>
          {children}
        </main>
        {LogoMKP}
      </div>
    </>
  )
}
