/** @jsx jsx */
import React from "react"
import { jsx, keyframes } from "@emotion/core"
import { LandingPageLayout, colorAnimationTime } from "../components/layout"
import {
  TypoLandingPageRegular,
  TypoLandingPageBold,
} from "../components/styles"

import logosnb19 from "../assets/images/logosnb20_small.svg"
import { desktop } from "../components/responsive"

const fgColorKeyframes = keyframes`
0% {
  color: #fff29c;
}
16.67% {
  color: #fcffc3;
}
33.33% {
  color: #d1ffbc;
}
50% {
  color: #9ee0d9;
}
66.67% {
  color: #d4ffbc;
}
83.33% {
  color: #9ee0d9;
}
100% {
  color: #fff29c;
}
`
const fgColorAnimation = {
  animation: `${fgColorKeyframes} ${colorAnimationTime} infinite`,
}

const TypoMKP = () => (
  <h2
    css={{
      alignSelf: `center`,
      marginBottom: `6.67vw`,
      ...desktop({ marginTop: `1.84vw`, marginBottom: `1.55vw` }),
    }}
  >
    <span css={{ ...TypoLandingPageRegular, ...fgColorAnimation }}>
      Migros-Kulturprozent
    </span>
  </h2>
)

const TypoMainLeft = () => {
  return (
    <h1
      css={{
        ...TypoLandingPageBold,
        marginLeft: `3.2vw`,
        ...fgColorAnimation,
      }}
    >
      Unser
      <br />
      Ball
      <br />
      für
      <br />
      alle
    </h1>
  )
}
const TypoMainRight = () => (
  <div
    css={{
      width: `46.1vw`,
      position: `absolute`,
      bottom: 0,
      right: `3.2vw`,
      ...desktop({ width: `27.28vw`, right: 0 }),
    }}
  >
    <img css={{ width: `100%` }} src={logosnb19} />
  </div>
)

const TypoLocation = () => (
  <h2
    css={{
      ...TypoLandingPageRegular,
      alignSelf: `center`,
      textAlign: `center`,
      marginBottom: 80,
      ...fgColorAnimation,
    }}
  >
    20. Juni 2020
    <br />
    im Zürcher Hauptbahnhof
  </h2>
)

export const IndexPage = () => (
  <LandingPageLayout>
    <TypoMKP />
    <div
      css={{
        position: `relative`,
        marginBottom: `8.4vw`,
        ...desktop({ marginBottom: `2.72vw` }),
      }}
    >
      <TypoMainLeft />
      <TypoMainRight />
    </div>
    <TypoLocation />
  </LandingPageLayout>
)

export default IndexPage
